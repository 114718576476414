window.__tcfapi('addEventListener', 2, (data) => {

    if (data == null || typeof data.eventStatus === "undefined")
        return;

    if (data.eventStatus !== "useractioncomplete" && data.eventStatus !== "tcloaded")
        return;

    if(typeof data.vendor.consents === "undefined")
        return;

    __tcfapi('getCustomVendorConsents', 2, (custom) => {
        const hubSpot = custom.consentedVendors.find((vendor) => vendor.name === "HubSpot, Inc.");
        if (hubSpot === undefined)
            return;

        const script = document.createElement('script');
        script.async = true;
        script.crossorigin = 'anonymous';
        script.type = 'text/javascript';
        script.src = "//js.hsforms.net/forms/embed/v2.js";
        document.head.appendChild(script);
    });

});
